enum Permission {
  MANAGE_ADMIN_USER = 'MANAGE_ADMIN_USER',
  MANAGE_MERCHANT_PAYOUT = 'MANAGE_MERCHANT_PAYOUT',
  MANAGE_MERCHANT_LEDGER = 'MANAGE_MERCHANT_LEDGER',
  MANAGE_COMPLIANCE_REQUEST = 'MANAGE_COMPLIANCE_REQUEST',
  MANAGE_MERCHANT_APPLICATION = 'MANAGE_MERCHANT_APPLICATION',
  MANAGE_MERCHANT_STATUS = 'MANAGE_MERCHANT_STATUS',
  MANAGE_MERCHANT_COMMENT = 'MANAGE_MERCHANT_COMMENT',
  MANAGE_MERCHANT_DOCUMENT = 'MANAGE_MERCHANT_DOCUMENT',
  READ_MERCHANT_PAYOUT = 'READ_MERCHANT_PAYOUT',
  READ_MERCHANT_PAYMENT = 'READ_MERCHANT_PAYMENT',
  READ_COMPLIANCE_REQUEST = 'READ_COMPLIANCE_REQUEST',
  READ_MERCHANT_TRANSACTION = 'READ_MERCHANT_TRANSACTION',
  READ_MERCHANT = 'READ_MERCHANT',
  READ_MERCHANT_DOCUMENT = 'READ_MERCHANT_DOCUMENT',
  READ_MEMBER = 'READ_MEMBER',
  READ_ADMIN_USER = 'READ_ADMIN_USER',
  MANAGE_MEMBER = 'MANAGE_MEMBER',
  MANAGE_MERCHANT = 'MANAGE_MERCHANT',
  READ_BILLING_PACKAGE = 'READ_BILLING_PACKAGE',
  READ_MERCHANT_BILLING_DEBT = 'READ_MERCHANT_BILLING_DEBT',
  MANAGE_MERCHANT_SETTINGS = 'MANAGE_MERCHANT_SETTINGS',
  READ_CASHBACK_CAMPAIGN = 'READ_CASHBACK_CAMPAIGN',
  MANAGE_CASHBACK_CAMPAIGN = 'MANAGE_CASHBACK_CAMPAIGN',
  MANAGE_MERCHANT_FEE = 'MANAGE_MERCHANT_FEE',
  MANAGE_BILLING_PACKAGE = 'MANAGE_BILLING_PACKAGE',
  READ_ADMIN_AUDIT_LOG = 'READ_ADMIN_AUDIT_LOG',
  MANAGE_MERCHANT_STATEMENT = 'MANAGE_MERCHANT_STATEMENT',
}

export default Permission;
